import classNames from 'classnames'
import { motion } from 'motion/react'
import React, { useState } from 'react'
import ControlledIcon from 'share-svgs/svgs/lock.svg'
import RefinedIcon from 'share-svgs/svgs/refined-search.svg'
import SafeIcon from 'share-svgs/svgs/shield.svg'
import { Box, Flex, HStack, Text, VStack } from 'ui'

const YourMostImportantSection = () => {
    return (
        <Box css={{ zIndex: 1, p: '$4', '@lg': { p: '50px 80px' } }}>
            <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0,
                    ease: [0.25, 0.1, 0.25, 1]
                }}
                viewport={{ once: true, amount: 0.1 }}
            >
                <VStack
                    justifyContent={'flexStart'}
                    alignItems={'flexStart'}
                    spacing={'8'}
                >
                    <Text
                        as="h2"
                        size={'5'}
                        weight={'ultraBold'}
                        css={{ '@md': { fontSize: '40px' } }}
                    >
                        Your Most Important Conversations...{' '}
                    </Text>
                    <Flex
                        css={{
                            flexDirection: 'column',
                            '@md': { flexDirection: 'row' }
                        }}
                        gap={'7'}
                    >
                        {HowAreStatemintsCards.map((card, index) => (
                            <HowAreStatemintsCard key={index} {...card} />
                        ))}
                    </Flex>
                </VStack>
            </motion.div>
        </Box>
    )
}

const HowAreStatemintsCard = ({
    title,
    description,
    Icon
}: {
    title: string
    description: string
    Icon: React.ComponentType
}) => {
    const [animationTriggered, setAnimationTriggered] = useState(false)

    const handleInteract = () => {
        if (!animationTriggered) {
            setAnimationTriggered(true)
        }
    }

    return (
        <VStack
            onMouseEnter={handleInteract}
            onTouchStart={handleInteract}
            alignItems={'flexStart'}
            spacing={'4'}
        >
            <Text weight={'bold'} size={'4'}>
                {title}
            </Text>
            <HStack alignItems={'flexStart'} spacing={'2'}>
                <Text weight={'medium'}>{description}</Text>
                <Box
                    justifyContent={'center'}
                    className={classNames(
                        { cardIconRefined: title === 'Refined' },
                        {
                            cardIconControlled:
                                title === 'Controlled Sharing & Permissions'
                        },
                        { cardIconSafe: title === 'Safe & Secure' }
                    )}
                    alignItems={'center'}
                    bRadius={'1'}
                    css={{
                        height: '118px',
                        width: '62px',
                        minWidth: '62px',
                        flexShrink: 0,
                        '&.cardIconRefined': { background: '$teal_500' },
                        '&.cardIconControlled': { background: '$orange_400' },
                        '&.cardIconSafe': { background: '$magenta_400' },
                        position: 'relative',
                        overflow: 'hidden',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: animationTriggered ? '-250%' : '150%',
                            left: '-110%',
                            width: '210px',
                            height: '120px',
                            transform: 'rotate(-34deg)',
                            background:
                                'linear-gradient(219deg, #CE261B 15.55%, #9B6CFF 51.73%, #FFC397 83.43%)',
                            transition: 'top 2s ease',
                            zIndex: 0
                        },
                        '& > *': {
                            position: 'relative',
                            zIndex: 1
                        }
                    }}
                >
                    <Icon />
                </Box>
            </HStack>
        </VStack>
    )
}

const HowAreStatemintsCards = [
    {
        title: 'Refined',
        description:
            'You decide who gets access. With advanced sharing controls and customizable permissions, you have full authority over your content. Whether collaborating with a team or keeping files private, our platform ensures your data stays in the right hands.',
        Icon: RefinedIcon
    },
    {
        title: 'Controlled Sharing & Permissions',
        description: `Quality matters. Our platform enhances and refines your content with precision, ensuring clarity and professionalism without compromising authenticity. From transcripts to files, we optimize every detail to meet the highest standards.`,
        Icon: ControlledIcon
    },
    {
        title: 'Safe & Secure',
        description:
            'We recognize the importance of safeguarding your content, which is why our platform is designed with security at its foundation. Every transcript, file, and interview is  protected by top-tier security measures, ensuring your data remains secure at all times.',
        Icon: SafeIcon
    }
]

export default YourMostImportantSection
