import LandingGrid from 'share-svgs/svgs/vault-landing-grid.svg'
import { Box, Flex } from 'ui'
import { styled } from 'ui/theme/stitches.config'
import LandingHero from './LandingHero'
import PartnersSection from './PartnersSection'
import YourMostImportantSection from './YourMostImportantSection'

const LandingPage = () => {
    return (
        <Box css={{ position: 'relative' }}>
            <Flex
                direction={'col'}
                css={{ position: 'absolute', zIndex: 1, height: '100%' }}
            >
                <GradientBackground />
                <GradientBackground
                    css={{
                        marginTop: '500px',
                        '@md': { display: 'none', marginTop: '0px' }
                    }}
                />
            </Flex>
            <div style={{ zIndex: 1 }}>
                <LandingHero />
                <YourMostImportantSection />
                <PartnersSection />
            </div>
            <LandingGridOne />
            <LandingGridTwo />
            <LandingGridThree />
        </Box>
    )
}

const LandingGridOne = styled(LandingGrid, {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'calc(75% + 10vw)',
    '@md': {
        left: '60%',
        transform: 'translateX(0%)',
        width: 'calc(50% + 10vw)'
    },
    '@lg': {
        left: '50%',
        // top: '-10%',
        width: 'calc(30% + 10vw)'
    }
})

const LandingGridTwo = styled(LandingGrid, {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'calc(75% + 10vw)',
    '@md': {
        left: '-20%',
        top: '15%',
        transform: 'translateX(0%)',
        width: 'calc(50% + 10vw)'
    },
    '@lg': {
        left: '-30%',
        top: '55%',
        width: 'calc(30% + 10vw)'
    }
})
const LandingGridThree = styled(LandingGrid, {
    position: 'absolute',
    top: '73%',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'calc(75% + 10vw)',
    '@md': {
        left: '-45%',
        top: '50%',
        transform: 'translateX(0%)',
        width: 'calc(50% + 10vw)'
    },
    '@lg': {
        left: '90%',
        top: '55%',
        width: 'calc(30% + 10vw)'
    }
})

const BackgroundContainer = styled('div', {
    backgroundColor: 'transparent',
    position: 'relative',
    width: '100%',
    height: '100%'
})

const GradientBackground = styled(BackgroundContainer, {
    '&::before': {
        content: '""',
        position: 'absolute',
        width: '300px',
        height: '2300px',
        top: '-750px',
        left: '40%',
        background:
            'linear-gradient(355deg, #100425 55.03%, rgba(16, 4, 37, 0.21) 95.79%)',
        filter: 'blur(52.5px)',
        transform: 'rotate(-60deg)',
        transformOrigin: 'center center',
        zIndex: 10,
        '@md': {
            background:
                'linear-gradient(355deg, #100425 55.03%, rgba(16, 4, 37, 0.21) 95.79%)',
            top: '-1000px',
            left: '10%'
        }
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        width: '300px',
        height: '2300px',
        top: '-400px',
        left: '30%',
        background:
            'linear-gradient(355deg,#100425 55.03%, rgba(16, 4, 37, 0.21) 95.79%)',
        filter: 'blur(52.5px)',
        transform: 'rotate(65deg)',
        transformOrigin: 'center center',
        zIndex: 10,
        '@md': {
            background:
                'linear-gradient(355deg,#100425 55.03%, rgba(16, 4, 37, 0.21) 95.79%)',
            top: '-350px',
            left: '70%'
        },
        '@lg': {
            top: '-200px',
            left: '35%',
            transform: 'rotate(60deg)'
        }
    },

    '& > *': {
        position: 'relative',
        zIndex: 1
    }
})

export default LandingPage
